
const array = [
    {
        id: 0,
        title: "सभी प्रकार के कीटनाशक तकनीकी",
        content: "विभिन्न प्रकार के कीटनाशक, फंगाइसाइड, और हर्बिसाइड्स",
        description: "यह उत्पाद फसल संरक्षा के लिए कीटनाशक, फंगाइसाइड, और हर्बिसाइड्स की एक व्यापक श्रृंखला प्रदान करता है। यह परियोजनाएं कीटों, कीटनाशकों, और खरपतवारों को नियंत्रित करने के लिए डिज़ाइन की गई हैं जो फसलों को क्षति पहुंचा सकते हैं और उत्पादन को कम कर सकते हैं। इन उत्पादों का निर्माण प्रभावी कीट प्रबंधन सुनिश्चित करने के लिए उन्नत प्रौद्योगिकियों का उपयोग करके किया जाता है जबकि पर्यावरणीय प्रभाव को कम किया जाता है।",
        dosage: "प्रत्येक उत्पाद के अनुसार अनुशंसित दिशानिर्देशों के रूप में",
        package: "50 लिटर / 200 लिटर (एचडीपीई ड्रम)"
    },
    {
        id: 1,
        title: "टपकते ग्रेड 1 के लिए सल्फेटेड माइक्रोन्यूट्रिएंट मिश्रण",
        content: "सल्फेटेड माइक्रोन्यूट्रिएंट मिश्रण",
        description: "टपकते इर्रिगेशन प्रणालियों के लिए सल्फेटेड माइक्रोन्यूट्रिएंट मिश्रण विशेष रूप से सृजित किया गया है ताकि टपक इर्रिगेशन के माध्यम से पौधों को आवश्यक माइक्रोन्यूट्रिएंट प्रदान किया जा सके। यह ग्रेड 1 मिश्रण सल्फर सहित विभिन्न माइक्रोन्यूट्रिएंट्स का संतुलित संयोजन शामिल करता है, जो पौधों में विभिन्न उपार्जनिक प्रक्रियाओं के लिए आवश्यक हैं, स्वस्थ वृद्धि और आदर्श उत्पादन को बढ़ावा देते हैं।",
        dosage: "टपक इर्रिगेशन प्रणालियों के लिए अनुशंसित दिशानिर्देशों के रूप में",
        package: "25 किलो (एचडीपीई बैग)"
    },
    {
        id: 2,
        title: "केलेटेड माइक्रोन्यूट्रिएंट्स मिश्रण ग्रेड 2",
        content: "केलेटेड माइक्रोन्यूट्रिएंट्स मिश्रण",
        description: "केलेटेड माइक्रोन्यूट्रिएंट्स मिश्रण ग्रेड 2 एक प्रीमियम तैयारी है जिसमें केलेटेड माइक्रोन्यूट्रिएंट्स शामिल हैं। केलेशन पौधों द्वारा माइक्रोन्यूट्रिएंट्स की उपलब्धता और सेंधन को बढ़ाता है, पौधों द्वारा नाइट्रिएंट उपयोग कोसुनिश्चित करता है। यह ग्रेड 2 मिश्रण पौधों में विभिन्न बायोकेमिकल प्रक्रियाओं के लिए आवश्यक माइक्रोन्यूट्रिएंट्स की एक संतुलित आपूर्ति प्रदान करता है, स्वस्थ वृद्धि और उत्पादन और गुणवत्ता में सुधार करता है।",
        dosage: "प्रत्येक फसल के लिए अनुशंसित दिशानिर्देशों के अनुसार",
        package: "25 किलो (एचडीपीई बैग)"
    },
    {
        id: 3,
        title: "मल्टी एक्टिविटी बायो आर्गेनिक दानेदार ग्रैन्यूल्स",
        content: "समुद्री जड़, एमिनो एसिड, ह्यूमिक एसिड, हर्बल एक्सट्रैक्ट और एंटी रूट रॉट पदार्थ",
        description: "समुद्री शाक के अर्क के आधार पर उत्पाद के कारण पौधों के कोशिका विभाजन के कारण संपूर्ण फसल की वृद्धि होती है। महत्वपूर्ण अमिनो अम्लों के मिश्रण के कारण, स्वस्थ और ताक़तवर वृद्धि होती है, जिससे फूलों और फलों की संख्या बढ़ती है। जड़ रोगाणु उपादानों के कारण, जड़ों के कीटाणु जैसे हानिकारक कवकों को मारकर मृदा को इस्त्रात कर दिया जाता है।> सभी उपरोक्त घटकों को एक अनुशिष्ट ग्रैन्यूलर रूप में मिलाकर। बहु क्रियात्मक जैविक ग्रैन्यूल्स को बाजार में उपलब्ध किसी भी अन्य ग्रैन्यूलर रूप की तुलना में अधिक प्रभावी पाया जाता है।",
        dosage: "प्रति एकड़ में 8 से 16 किलो जैसा कि प्रकार के कीट",
        package: "25 किलो (एचडीपीई बैग)"
    },
    {
        id: 4,
        content: "समुद्री जड़, एमिनो और ह्यूमिक एसिड आधारित ग्रैन्यूल्स",
        title: "तीन में एक ग्रैन्यूल्स (समुद्री जड़, एमिनो, ह्यूमिक एसिड)",
        description: "समुद्री जड़, एमिनो और ह्यूमिक एसिड आधारित ग्रैन्यूल्स एक अद्वितीय मिश्रण है जो समुद्री जड़ के उत्पाद के कारण पौधों के समग्र विकास को बढ़ावा देने के लिए एमिनो एसिड और ह्यूमिक एसिड के लाभ को संयुक्त करता है। समुद्री जड़ आधारित उत्पाद के कारण पौधों की कुल वृद्धि होती है क्योंकि पौधों की कोशिका विभाजन होता है। महत्वपूर्ण एमिनो एसिड के मिश्रण के कारण, स्वस्थ और उत्साही वृद्धि होती है जिसमें फूलों और फलों की संख्या बढ़ती है। ह्यूमिक एसिड के कारण, श्वेत जड़ों की प्रवृद्धि होती है। मिट्टी में ह्यूमिक एसिड की उपस्थिति मिट्टी में मौजूद खनिजों का आसानी से अवलोकन करने में मदद करती है जिन्हें उनके संबंधित ह्यूमेट रूप में परिवर्तित करके।",
        dosage: "प्रति एकड़ 10 किलो",
        package: "25 किलो (एचडीपीई बैग)"
    },
    {
        id: 5,
        title: "समुद्री जड़ के आधारित ग्रैन्यूल्स",
        content: "समुद्री जड़ का अर्क",
        description: "समुद्री जड़ के आधारित ग्रैन्यूल्स प्राकृतिक समुद्री स्रोतों से प्राप्त किए जाते हैं। वे पौधों के विकास और विकास को बढ़ावा देने वाले पोषक पोषण तत्वों और वृद्धि प्रोत्साहक पदार्थों की एक श्रृंखला होते हैं जो पौधों की जड़ संवर्धन, पोषक अवशोषण में सुधार, और पौधों की तनाव प्रतिरोधीता को बढ़ाते हैं। यह भी सम्पूर्ण पौधे की स्वास्थ्य और उत्साह को बढ़ावा देता है।",
        dosage: "प्रति एकड़ 10 किलो",
        package: "25 किलो (एचडीपीई बैग)"
    },
    {
        id: 6,
        title: "ह्यूमिक एसिड ग्रैन्यूल्स",
        content: "ह्यूमिक एसिड",
        description: "ह्यूमिक एसिड ग्रैन्यूल्स मिट्टी का एसिड-आधारित उपचार करने की क्षमता होती है, जिससे वे विभिन्न मिट्टी के प्रकारों के लिए उपयुक्त होते हैं। वे मिट्टी को नेचुरलाइज कर सकते हैं अम्लीय या क्षारीय मिट्टियों को, पौधों के वृद्धि के लिए एक अधिक संतुलित पीएच स्तर बनाते हैं। यह मिट्टी की संरचना को सुधारता है, माइक्रोबियल गतिविधि को प्रोत्साहित करता है, और पोषक तत्व की उपलब्धता को बढ़ाता है।",
        dosage: "प्रति एकड़ 10 किलो",
        package: "25 किलो (एचडीपीई बैग)"
    },
    {
        id: 7,
        title: "सभी फसलों के लिए बायोस्टिमुलेंट्स",
        content: "एमिनो एसिड, विटामिन, प्रोटीन सीवीड एक्सट्रेक्ट, नवाचारी और आधुनिक बायो-स्टिमुलेंट जो वृद्धि और उत्पादन में वृद्धि के लिए हाई क्वालिटी के साथ वृद्धि के लिए सिफारिश की जाती है।",
        description: "सभी फसलों के लिए बायो स्टिमुलेंट फॉर ऑल क्रॉप्स को सभी सब्जियों, ग्राउंडनट, अंगूर, कपास, तिलहन और मिर्ची के लिए पत्तियों और मिट्टी के लिए उत्तेजन की सिफारिश की जाती है। बायो स्टिमुलेंट फॉर ऑल क्रॉप्स को फोलियर या यह मूल रूप से जड़ के पास धारावाहिक या इसे इस्तेमाल किया जा सकता है। बायो स्टिमुलेंट फॉर ऑल क्रॉप्स निम्नलिखित कार्यों को लाते हैं: एक पौधों का समग्र वृद्धि। यह फूलों और फलों की संख्या बढ़ाता है। यह फलों पर चमक, मिलावट को बढ़ाता है और उत्पाद की अधिकतम आय को बढ़ाता है।",
        dosage: "खाद्यानुकूलन के लिए: प्रति 100 लीटर पानी में 25 मिली बायो स्टिमुलेंट फॉर ऑल क्रॉप्स। (प्रति लीटर पानी 0.5 मिली - 1 मिली बायो स्टिमुलेंट फॉर ऑल क्रॉप्स) आवेदन का समय: 1 वीं छिड़काव - फूलने की चरण, 2 वीं छिड़काव - फल सेटिंग स्थिति में (पहले छिड़काव के 20 दिनों बाद), 3 वें छिड़काव: फल विकास चरण में (दूसरे छिड़काव के 15 - 20 दिन बाद), 4 वें छिड़काव: आवश्यकतानुसार।",
        package: "50 लीटर / 200 लीटर (एचडीपीई ड्रम)",
    },
    {
        id: 8,
        title: "फ्लावरिंग एन्हांसर और फ्रूट सेटर",
        description: "फ्लावरिंग एन्हांसर और फ्रूट सेटर एक अद्वितीय जड़ी-बूटी उत्पाद है जो प्रजननी फूलों की संख्या बढ़ाता है। इस परिणाम में फल का और अधिक निर्माण होता है और अत्यधिक उत्पाद बढ़ जाता है। 30-40%। फ्लावरिंग एन्हांसर और फ्रूट सेटर फूल और फलों का गिरावट भी कम करता है। फ्लावरिंग एन्हांसर और फ्रूट सेटर बड़े आकार के फल भी देता है।",
        dosage: "प्रति लीटर पानी में 2 मिली फ्लावरिंग एन्हांसर और फ्रूट सेटर का उपयोग करें। आवेदन का समय: -1 वीं छिड़काव - फूलने के पहले 25-30 दिन। 2 वा स्प्रे - 15-20 दिन फूलों के बाद।",
        crops: "सभी प्रकार की सब्जियों और बागवानी की फसलों के लिए उपयोग करें, जैसे कि आम, सीताफल, पपीता, अनार आदि।",
        package: "50 लीटर / 200 लीटर (एचडीपीई ड्रम)",
    },
    {
        id: 9,
        title: "एमिनो और खनिज आधारित पौधे की वृद्धि को बढ़ाने वाला प्रोमोटर",
        content: "समुद्री जड़ अर्क, खनिज, केसिन प्रोटीन, जल",
        description: "एमिनो एसिड प्लस मिनरल बेस्ड प्लांट ग्रोथ प्रोमोटर एमिनो एसिड आधारित उत्पाद है। एमिनो एसिड पौधों को नाइट्रोजन प्रदान करता है और प्रोटीन निर्माण को भी प्रेरित करता है। यह पौधों के समस्त चरणों में उन्नत विकास लाता है। यह कोशिका विभाजन और कोशिका निर्माण में मदद करता है। यह फोटोसिंथेसिस को बढ़ाता है और पौधों के पूर्ण विकास को लाता है जिसमें परिपोलन और फलों का निर्माण होता है। यह अधिक मात्रा में परिपोलन और फल निर्माण बढ़ाता है। यह भी माइक्रो फ्लोरा को सुधारता है, जिससे पोषक पदार्थ संविलित होते हैं, जो फिर में, पोषक उत्सर्जन को सुविधाजनक बनाते हैं।",
        dosage: "प्रति लीटर पानी में 2 मिली",
        timeOfApplication: "आवेदन का समय: -1 वा स्प्रे: परिपोषण / अंकुरण के 15 - 20 दिन बाद। 2 वा स्प्रे: फूलावरण या फल बनाने की चरण (पहले स्प्रे के 20 दिन बाद), उपलब्ध पैकिंग: - 50 लीटर / 200 लीटर (एचडीपीई ड्रम)",
        package: "50 लीटर / 200 लीटर (एचडीपीई ड्रम)",
    },
    {
        id: 10,
        title: "सिलिकॉन आधारित छांटने और एजुवेंट",
        content: "सिलिकॉन",
        description: "सिलिकॉन आधारित छांटने, पेनेट्रेटर और एजुवेंट सिलिकॉन आधारित गैर - आइयोनिक सर्फैक्टेंट है। यह कृषि स्प्रे के प्रभावकारीता को बढ़ाता है और इसकी मात्रा को भी कम करता है। सिलिकॉन आधारित छांटने, पेनेट्रेटर और एजुवेंट पत्ती की सतह पर तेजी से फैलता है, जो परिपोषण, चिपकाव और वर्षा के खिलाफ प्रभाव को बढ़ाता है। यह पत्ती की सतह पर समान रूप से फैलता है और पौधों द्वारा आसानी से अवशोषित किया जाता है क्योंकि इसका कम विस्कोसिटी और आणुजीविता होता है। सिलिकॉन आधारित छांटने, पेनेट्रेटर और एजुवेंट की निष्कर्षता, कीटनाशक, फंगाइसाइड, हर्बाइसाइड, पीजीआर और पोषक खादों की प्रभावकारिता को बढ़ाता है।",
        dosage: "100 लीटर पानी के लिए 25 मिली",
        package: "50 लीटर / 200 लीटर (एचडीपीई ड्रम)",
    },
    {
        id: 11,
        title: "डी सोडियम ऑक्टा बोरेट टेट्रा हाइड्रेट बोरॉन 20%",
        content: "डी सोडियम ऑक्टा बोरेट टेट्रा हाइड्रेट बोरॉन 20%",
        description: "डी सोडियम ऑक्टा बोरेट टेट्रा हाइड्रेट, जिसे बोरॉन 20% के रूप में भी जाना जाता है, एक बोरॉन युक्तियाँ खाद्यानुत कंटेनिंग खनिज है। बोरॉन पौधों में विभिन्न भौतिक प्रक्रियाओं में आवश्यक है, जिसमें कोशिका दीवार निर्माण, शर्करा परिवहन, और जननीय विकास शामिल हैं। यह उत्पाद बोरॉन की कमी को रोकता है और फसलों में स्वस्थ वृद्धि और आदर्श उत्पादन को प्रोत्साहित करता है।",
        dosage: "1-2 किलो / एकड़",
        package: "25 किलो (एचडीपीई बैग)"
    },
    {
        id: 12,
        title: "जिंक ईडीटीए",
        content: "जिंक ईडीटीए",
        description: "जिंक ईडीटीए जिंक का एक चेलेटेड रूप है, जहां जिंक को ईडीटीए (इथाइलीनडाइमिनटेटेट्राएसेटिक एसिड) के साथ एक स्थिर संयोजन बनाया जाता है। चेलेशन पौधों के लिए जिंक की उपलब्धता को बढ़ाता है, जिससे यह जड़ों के माध्यम से अधिक आसानी से अवशोषित होता है। जिंक पौधों में विभिन्न एंजाइमेटिक प्रतिक्रियाओं के लिए आवश्यक है, जिनमें फोटोसिंथेसिस और हार्मोन नियंत्रण शामिल हैं। जिंक ईडीटीए फसलों में जिंक की कमी को रोकता है और स्वस्थ वृद्धि और विकास को प्रोत्साहित करता है।",
        dosage: "1-2 किलो / एकड़",
        package: "25 किलो (एचडीपीई बैग)"
    }
]
export default array;