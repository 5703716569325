export const menuItemsEnglish = [{
    title: "Home",
    url: "",
},
{
    title: "About",
    url: "about",
},
{
    title: "Products",

    submenu: [{
        title: "All Products",
        url: "products",
    }, {
        title: "Crop Protection",
        submenu: [{
            title: "Insecticides",
            url: "products/insecticides",
        },
        {
            title: "Fungicides",
            url: "products/fungicides",
        },
        {
            title: "Herbicides",
            url: "products/herbicides",
        }
        ],
    },
    {
        title: "Micro-Nutrients Fertilizers",
        url: "products/micro-nutrients-fertilizers",

    },
    {
        title: "Plant Growth Regulators",
        url: "products/plant-growth-regulators",
    },
    {
        title: "Bio-Fertilizers",
        url: "products/bio-fertilizers",
    }
    ],
},
{
    title: "Media",
    submenu: [{
        title: "New launch",
        url: "media/new-launch",
    },
    {
        title: "Company News",
        url: "media/company-news",
    },
    ],
},
{
    title: "Gallery",
    submenu: [{
        title: "Annual Dealers Meet",
        url: "gallery/annual-dealers-meet",
    },
    {
        title: "Field Visits",
        url: "gallery/field-visits",
    },
    {
        title: "Tour Photos",
        url: "gallery/tour-photos",
    },
    {
        title: "Video News",
        url: "gallery/video-news",
    },
    {
        title: "Farmer's Meeting",
        url: "gallery/farmers-meet",
    }
    ],
},
{
    title: "Enquiry",
    url: "enquiry",
},
{
    title: "Careers",
    url: "careers",
},
{
    title: "B2B",
    url: "b2b",
},
{
    title: "Contact",
    url: "contact",
}
];