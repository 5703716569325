const array = [
    {
        id: 0,
        title: " सर्व प्रकारचे कीटनाशक तांत्रिक",
        content: "विविध प्रकारच्या किटकनाशक, फंगाईसाइड आणि वनस्पतीनाशक",
        description: "या उत्पादनाने फसल संरक्षणासाठी किटकनाशक, फंगाईसाइड आणि वनस्पतीनाशकांची एक व्यापक श्रेणी प्रस्तुत करते. हे कीट जसे की कीड, फंगस आणि वनस्पतींना वर्तवलेले फसळे आणि येळ्या तयार करण्यासाठी तयार केलेले संशोधन योजना समाविष्ट करते. या उत्पादनांमध्ये एक्जिड्वॉन्स तंत्रज्ञानाचा वापर करून किट प्रबंधनाची प्रभावी कामगिरी योजना व कोणत्याही पर्यावरणीय प्रभावांचे कमी करणे सुनिश्चित करण्यासाठी तयार केले जातात.",
        dosage: "प्रत्येक उत्पादनासाठी शिफारस केलेल्या दिशानिर्देशांनुसार",
        package: "50 लिटर / 200 लिटर (एचडीपीई ड्रम)"
    },
    {
        id: 1,
        title: "ड्रिप ग्रेड 1साठी सल्फेटेड मायक्रोन्यूट्रिएंट मिश्रण",
        content: "सल्फेटेड मायक्रोन्यूट्रिएंट मिश्रण",
        description: "ड्रिप इरिगेशन प्रणालियांसाठी सल्फेटेड मायक्रोन्यूट्रिएंट मिश्रण विशेषतः फसळांना ड्रिप इरिगेशन द्वारे आवश्यक मायक्रोन्यूट्रिएंट पुरवण्यासाठी तयार केलेले आहे. हा ग्रेड 1 मिश्रण सल्फरसह, ज्यामध्ये वनस्पतींना विविध अणुसंशोधनांमध्ये महत्वाच्या मायक्रोन्यूट्रिएंट योजना असते, ज्यामध्ये सल्फर असतो, ज्याचा वनस्पतींमध्ये विविध अणुसंशोधनांसाठी महत्वाचा काम करतो. हा ग्रेड 1 मिश्रण वनस्पतींच्या विविध जैवरसायनिक प्रक्रियांसाठी आवश्यक मायक्रोन्यूट्रिएंट पुरवतो, ज्यामध्ये फसळांच्या सामान्य वृद्धी, आणि उत्तम उत्पादन आणि गुणवत्ता सोडवण्यासाठी आवश्यक आहे.",
        dosage: "ड्रिप इरिगेशन प्रणालियांसाठी शिफारस केलेल्या दिशानिर्देशांनुसार",
        package: "25 किलोग्राम (एचडीपीई बॅग)"
    },
    {
        id: 2,
        title: "शेलेटेड मायक्रोन्यूट्रिएंट्स मिश्रण ग्रेड 2",
        content: "शेलेटेड मायक्रोन्यूट्रिएंट्स मिश्रण",
        description: "शेलेटेड मायक्रोन्यूट्रिएंट्स मिश्रण ग्रेड 2 प्रीमियम योजना आहे ज्यामध्ये शेलेटेड मायक्रोन्यूट्रिएंट्स आहेत. शेलेशन पिकांना मायक्रोन्यूट्रिएंट्सची उपलब्धता आणि आरोग्यपूर्ण मायक्रोन्यूट्रिएंट्स आपल्या द्वारे अवलंबून घेण्याची क्षमता वाढवते, खालील कमी नुकसानदायक नुकसानदायक पर्यावरणीय प्रभाव सापडले आहेत. हा ग्रेड 2 मिश्रण पिकांमध्ये विविध बायोकॅमिकल प्रक्रियांसाठी आवश्यक आहेत आहेत, ज्यामध्ये आरोग्यदायक वाढ, आणि यील्ड आणि गुणवत्ता वाढविण्यासाठी आवश्यक असलेल्या आवश्यक मायक्रोन्यूट्रिएंट्सची संतुलित दुकान वाटप,",
        dosage: "प्रत्येक फसळासाठी शिफारस केलेल्या दिशानिर्देशांनुसार",
        package: "25 किलोग्राम (एचडीपीई बॅग)"
    },
    {
        id: 3,
        title: "मल्टी ऍक्टिव्हिटी बायो ऑर्गॅनिक ग्रॅन्युल्स",
        content: "समुद्र शिवार अर्क, अमिनो ऍसिड, ह्यूमिक ऍसिड, आजीवनिक अर्क आणि रुग्णपथ नाशक पदार्थे",
        description: "समुद्राच्या शाकाच्या अर्काच्या आधारे उत्पादाच्या कारणे प्लॉट कोशिका विभागीच्या कारण झाल्याने झालेली वृद्धी असते. महत्त्वाच्या अमिनो ऍसिड्सच्या मिश्रणाच्या कारणे, स्वस्थ व ताकदवान वृद्धी होते, ज्यामुळे फूल व फळांची संख्या वाढते. मूळरोगनाशी उपद्रवी पदार्थांच्या कारणे, मृदाचा तटस्थकीर्दी किटाणु चे मारून मृदाचा स्वच्छीकरण होतो. > सर्व वरील घटकांची एका ग्रॅन्युलर रूपात मिसळण केल्याने. बहु क्रियात्मक जैविक ग्रॅन्युल्स बाजारात उपलब्ध असलेल्या कोणत्याही इतर ग्रॅन्युलर रूपापेक्षा अधिक प्रभावी मानले जाते.",
        dosage: "प्रति एकर 8 ते 16 किलोग्रॅम नियमित कीटकांकित पिकांसाठी",
        package: "25 किलोग्राम (एचडीपीई बॅग)"
    },
    {
        id: 4,
        title: "तीन इन वन ग्रॅन्युल्स (समुद्र शिवार, अमिनो, ह्यूमिक ऍसिड)",
        content: "समुद्र शिवार, अमिनो आणि ह्यूमिक ऍसिड आधारित ग्रॅन्युल्स",
        description: "समुद्र शिवार, अमिनो आणि ह्यूमिक ऍसिड आधारित ग्रॅन्युल्स एक अद्वितीय भेट योजली आहे ज्याचा उपयोग करून सम्पूर्ण पादपी विकासाचे फायदे मिळतात. समुद्र शिवार अर्क आधारित उत्पादनामुळे फसळांचा समग्र वृद्धी होतो, अन्य संघटकांनी खालील अणुक्लेउशन, आणि पिकांची तनावाती संघटना मदत करते. ह्यूमिक ऍसिडचा उपस्थित आहे, त्यामुळे श्वेत रड्या वृद्धी होते. मृदा मध्ये ह्यूमिक ऍसिडची उपस्थिती मृदातील उपलब्ध आणि त्यांची नुसत्यास सोडवण्यास मदत करते.",
        dosage: "प्रति एकर 10 किलोग्रॅम",
        package: "25 किलोग्राम (एचडीपीई बॅग)"
    },
    {
        id: 5,
        title: "समुद्र शिवार अर्क आधारित ग्रॅन्युल्स",
        content: "समुद्र शिवार अर्क",
        description: "समुद्र शिवार अर्क आधारित ग्रॅन्युल्स प्राकृतिक समुद्र शिवार स्रोतांपासून प्राप्त केले जातात. त्यात विविध उपयुक्त पोषण आणि वृद्धी-संशोधक पदार्थ असतात ज्यांनी पादपी वृद्धी आणि विकासाचे संजीवन केले आहे. समुद्र शिवार अर्क रुद्ध वृद्धीस्तिमुल, पोषण गोळा, आणि पिकांची तनावाती प्रतिस्थापन करते. तो पाण्याच्या सुलभतेमध्ये नुत्रियंत्रण घेऊन त्यातील पोषण पाहण्याचे मदत करते.",
        dosage: "प्रति एकर 10 किलोग्रॅम",
        package: "25 किलोग्राम (एचडीपीई बॅग)"
    },
    {
        id: 6,
        title: "ह्यूमिक ऍसिड ग्रॅन्युल्स",
        content: "ह्यूमिक ऍसिड",
        description: "ह्यूमिक ऍसिड ग्रॅन्युल्स मृदाचा ओरडा संतुलित करण्याची क्षमता असताना विविध मृदाच्या प्रकारांसाठी उपयुक्त आहेत. ते अम्लीय किंवा क्षारीय मृदांचे न्यूट्रल करू शकतात, वनस्पतींच्या वृद्धीसाठी संरक्षक योग्यता असतात. मृदाची संरचना सुधारित करतात, क्षेत्रीय जीवनस्वास्थ्य बढवतात, आणि पोषण पाचणीची उपलब्धता वाढवतात.",
        dosage: "प्रति एकर 10 किलोग्रॅम",
        package: "25 किलोग्राम (एचडीपीई बॅग)"
    },
    {
        id: 7,
        title: "सर्व पिकांसाठी बायोस्टिमुलंट्स",
        content: "अमिनो ऍसिड्स, व्हिटॅमिन्स, प्रोटिन्स समुद्र शिवार अर्क, नवीन आणि आधुनिक बायोस्टिमुलंट प्रेरण आणि उत्पन्नाच्या वाढ आणि उच्च गुणवत्तेच्या सहाय्याने.",
        description: "बायोस्टिमुलंट फॉर ऑल क्रॉप्सची फोलिअर आणि मृदच्या अर्जावर सुचविण्यासाठी असते फळभोवती आणि समीपवर्ती फूलांची प्रोत्साहन आणि नंतर यिल्डची वाढी झाली आहे. बायोस्टिमुलंट फॉर ऑल क्रॉप्सचा फोलिअर असो नेहमी व्यवस्थापनात आहे किंवा तो रूद्ध आधुनिकता संपादन योजना असू शकतो.",
        dosage: "खेतीसाठी 100 लिटर पाणीत 25 मिलीलीटर बायोस्टिमुलंट फॉर ऑल क्रॉप्स वापरा. (0.5 मिलीलीटर - 1 मिलीलीटर बायोस्टिमुलंट फॉर ऑल क्रॉप्स प्रत्येक लिटर पाण)",
        package: "50 लिटर / 200 लिटर (एचडीपीई ड्रम)"
    },
    {
        id: 8,
        title: "फ्लावरिंग एन्हॅन्सर आणि फ्रूट सेटर",
        description: "फ्लावरिंग एन्हॅन्सर & फ्रूट सेटर हे अनोळखी औषध उत्पाद आहे ज्याने प्रजनन फूलांची संख्या वाढवते. या परिणामी फळ निर्माण आणि त्यामुळे अखेरीस उत्पादन 30-40% वाढते. फ्लावरिंग एन्हॅन्सर & फ्रूट सेटर फुलांचे अंतर आणि फळांचे गाळणे कमी करते. फ्लावरिंग एन्हॅन्सर & फ्रूट सेटर ह्या जास्त प्रकारच्या फळांची आहे.",
        dosage: "प्रत्येक लिटर पाण्यात 2 मिली फ्लावरिंग एन्हॅन्सर & फ्रूट सेटर वापरा. अर्ज करण्याचा वेळ: - 1 ला स्प्रे - फुलांच्या सुरुवातीसाठी 25-30 दिवस. 2 ने स्प्रे - फुलांची अंदाज झाल्यानंतर 15-20 दिवस नंतर.",
        crops: "सर्व प्रकारच्या भाज्यांचे आणि उद्यानी फसळ्यांसाठी वापरा जसे की आंबा, सीताफळ, पपई आणि डाळींदार प्रकारे.",
        package: "50 लिटर / 200 लिटर (एचडीपीई ड्रम)"
    },
    {
        id: 9,
        title: "अमिनो आणि खनिज आधारित प्लांट ग्रोथ प्रमोटर",
        content: "समुद्र शिवार अर्क, खनिज, केसिन प्रोटिन, अक्वा",
        description: "अमिनो ऍसिड प्लस खनिज आधारित प्लांट ग्रोथ प्रमोटर अनेक वास्तविक प्रभावी आणि उत्पादनदायक खाणी घेऊन सजीवन घेते. या उत्पादनामुळे प्रत्येक फसळात अत्यंत उत्तम पाचन आणि फूलबाग क्रियाशीलता बळजवण्यात आल्याचं प्रमाणित केलं जातं. त्यामुळे अधिक फुल, फळांची संख्या वाढते आणि त्याच्यामार्फत उत्पादन वाढतो.",
        dosage: "प्रत्येक लिटर पाण्यात 2 मिली अमिनो ऍसिड प्लस खनिज आधारित प्लांट ग्रोथ प्रमोटर वापरा.",
        package: "50 लिटर / 200 लिटर (एचडीपीई ड्रम)"
    },
    {
        id: 10,
        title: "सर्व समस्यांसाठी अवजार",
        content: "सर्व प्रकारच्या फसळ्यांसाठी पूर्ण समाधान",
        description: "फिटनेस अवजार हे एक मिश्रित उत्पादन आहे ज्यामध्ये विविध स्थिरता, निर्माणशीलता, स्नायु-स्नायूशक्ती, संवेदनशीलता आणि पाचनक्षमता वाढवणारे पदार्थ आहेत. त्यामुळे उत्पादनशीलता वाढते आणि संयोजन फुलांचा वाढतो.",
        dosage: "प्रत्येक लिटर पाण्यात 1 मिलीटर फिटनेस अवजार वापरा.",
        package: "50 लिटर / 200 लिटर (एचडीपीई ड्रम)"
    },
    {
        id: 11,
        title: "फ्रेश अँड विगरस",
        content: "समुद्र शिवार अर्क, ब्राउन स्युगर, एलआरफ़",
        description: "फ्रेश अँड विगरस हे शाकाहारी अन्नधान्यांच्या प्रमोशनसाठी आहे. या उत्पादनाने फुलांची संख्या वाढते, पाचन वाढते आणि अंदाज तेजीने होते. त्यामुळे उत्पादन जास्त असताना उच्च गुणवत्तेचा फल मिळतो.",
        dosage: "प्रत्येक लिटर पाण्यात 2 मिलीटर फ्रेश अँड विगरस वापरा.",
        package: "50 लिटर / 200 लिटर (एचडीपीई ड्रम)"
    },
    {
        id: 12,
        title: "अँटीस्ट्रेसेंट्स",
        content: "प्राणवायु, स्ट्रेसोर्स, पर्वाण्यांचे निरोगी वातावरण, सार्वजनिक स्थल, अजून संवेदनशील आणि पाचनक्षमता",
        description: "फिटनेस अँटीस्ट्रेसेंट्स हे फसळांना स्थिर वातावरण आणि स्नायु-स्नायूशक्ती तयार करण्यासाठी एक मिश्रित उत्पादन आहे. त्यामुळे प्रसार, प्रोटीन संशोधन, आणि पाचन वाढते.",
        dosage: "प्रत्येक लिटर पाण्यात 1 मिलीटर फिटनेस अँटीस्ट्रेसेंट्स वापरा.",
        package: "50 लिटर / 200 लिटर (एचडीपीई ड्रम)"
    }
]

export default array;