export const menuItemsMarathi = [{
    title: "होम",
    url: "",
},
{
    title: "विषयी",
    url: "about",
},
{
    title: "उत्पादने",

    submenu: [{
        title: "सर्व उत्पादने",
        url: "products",
    }, {
        title: "फवारणी उत्पादने",
        submenu: [{
            title: "कीटनाशके",
            url: "products/insecticides",
        },
        {
            title: "फफगळनाशके",
            url: "products/fungicides",
        },
        {
            title: "वनस्पतीनाशके",
            url: "products/herbicides",
        }
        ],
    },
    {
        title: "मायक्रो-पोषक खते",
        url: "products/micro-nutrients-fertilizers",

    },
    {
        title: "वनस्पती विकास नियंत्रके",
        url: "products/plant-growth-regulators",
    },
    {
        title: "जैव-खते",
        url: "products/bio-fertilizers",
    }
    ],
},
{
    title: "मीडिया",
    submenu: [{
        title: "नवीन लॉन्च",
        url: "media/new-launch",
    },
    {
        title: "कंपनीची बातम्या",
        url: "media/company-news",
    },
    ],
},
{
    title: "गॅलरी",
    submenu: [{
        title: "वार्षिक विक्रेता सभा",
        url: "gallery/annual-dealers-meet",
    },
    {
        title: "फील्ड भ्रमणे",
        url: "gallery/field-visits",
    },
    {
        title: "प्रवास फोटो",
        url: "gallery/tour-photos",
    },
    {
        title: "व्हिडिओ बातम्या",
        url: "gallery/video-news",
    },
    {
        title: "किसान संगणक",
        url: "gallery/farmers-meet",
    }
    ],
},
{
    title: "विचारांची विचारांची",
    url: "enquiry",
},
{
    title: "व्यवसाय",
    url: "careers",
},
{
    title: "व्यापार-व्यापार",
    url: "b2b",
},
{
    title: "संपर्क",
    url: "contact",
}
];