export const menuItemsHindi = [{
    title: "होम",
    url: "",
},
{
    title: "हमारे बारे में",
    url: "about",
},
{
    title: "उत्पाद",

    submenu: [{
        title: "सभी उत्पाद",
        url: "products",
    }, {
        title: "फसल संरक्षण",
        submenu: [{
            title: "कीटनाशक",
            url: "products/insecticides",
        },
        {
            title: "फंगीसाइड्स",
            url: "products/fungicides",
        },
        {
            title: "हरबिसाइड्स",
            url: "products/herbicides",
        }
        ],
    },
    {
        title: "माइक्रो-पोषक उर्वरक",
        url: "products/micro-nutrients-fertilizers",

    },
    {
        title: "पौधों के विकास नियंत्रक",
        url: "products/plant-growth-regulators",
    },
    {
        title: "बायो-उर्वरक",
        url: "products/bio-fertilizers",
    }
    ],
},
{
    title: "मीडिया",
    submenu: [{
        title: "नई लॉन्च",
        url: "media/new-launch",
    },
    {
        title: "कंपनी की खबरें",
        url: "media/company-news",
    },
    ],
},
{
    title: "गैलरी",
    submenu: [{
        title: "वार्षिक डीलर्स मीट",
        url: "gallery/annual-dealers-meet",
    },
    {
        title: "फ़ील्ड विज़िट",
        url: "gallery/field-visits",
    },
    {
        title: "यात्रा फ़ोटो",
        url: "gallery/tour-photos",
    },
    {
        title: "वीडियो समाचार",
        url: "gallery/video-news",
    },
    {
        title: "किसानों से मिलना",
        url: "gallery/farmers-meet",
    }
    ],
},
{
    title: "पूछताछ",
    url: "enquiry",
},
{
    title: "करियर",
    url: "careers",
},
{
    title: "व्यापार-से-व्यापार",
    url: "b2b",
},
{
    title: "संपर्क",
    url: "contact",
}
];